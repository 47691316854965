import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { DateService } from './services/date.service';
import { DatePipe } from '@angular/common';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { CommonService } from './helpers/common.service';
import { NgChartsModule } from 'ng2-charts';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { CalendarModule } from 'ion2-calendar';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
// import { DaterangepickerModule } from 'angular-2-daterangepicker';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { NgSelectModule } from '@ng-select/ng-select';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { IonicRouteStrategy, provideIonicAngular, IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Drivers } from '@ionic/storage';
import { StorageServiceAAA } from './helpers/aaa-storage.service';
import { PayDetailsService } from './pages/pay-details/pay-details.service';
import { TotalClientService } from './components/total-clients/total-clients.service';
import { Appsflyer } from '@awesome-cordova-plugins/appsflyer/ngx';
import {
	ApmModule,
	ApmService,
	ApmErrorHandler,
  } from '@elastic/apm-rum-angular';
import { ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [AppComponent, AppLoaderComponent],
	// entryComponents: [],
	imports: [BrowserModule, IonicModule.forRoot({
        mode: 'md'
    }),
        AppRoutingModule, HttpClientModule,
		IonicStorageModule.forRoot(),
		// DaterangepickerModule,
		// CalendarModule,		review
		NgChartsModule,
		IonicStorageModule.forRoot({
            driverOrder: [Drivers.SecureStorage, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
		// NgSelectModule
		ApmModule,
		BrowserAnimationsModule
	],
	providers: [
		Title,
		HttpClientModule,
		HTTP,
		DatePipe,
		Network,
		StorageServiceAAA,
        PayDetailsService,
		CommonService,
		// FirebaseX,
		InAppBrowser,
		FingerprintAIO,
		CleverTap,
		FileTransfer,
		FileTransferObject,
		FileOpener,
		File,
		TotalClientService,
		Appsflyer,
		ApmService,
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		  },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		provideAnimations()
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(service: ApmService) {
		// Configure the APM service
		const apm = service.init({
		  serviceName: 'uat-aaa-angular-app',
		  serverUrl: 'https://broking-uat-apigateway.indiainfoline.com/apm',
		  serviceVersion: '',
		  environment: 'uat',
		  apmRequest({ xhr }) {
			xhr.setRequestHeader(
			  'Ocp-Apim-Subscription-Key',
			  'c98d4f603a9b4c1bbfbd1d49642bdd95'
			);
			return true;
		  },
		});
	
		apm.addLabels({ Application: 'uat-aaa-angular-app' });

		apm.setCustomContext({
		  Application: 'UAT AAA Angular App',
		});
	  }
 }
